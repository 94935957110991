<template>
    <Component
        :is="layout"
        class="c-homepage"
        :flushWithHeader="true"
        :noBottomPadding="!loggedIn"
    >
        <slot></slot>
        <template v-if="loggedIn">
            <Component
                :is="contentfulContentBlock"
                :contentRows="authenticatedHomepageContent"
                :contentTitle="homepageContent.title"
            />
        </template>
        <Component
            :is="anonymousHomepage"
            v-else
            :content="anonymousHomepageContent"
            :contentTitle="homepageContent.title"
        />
    </Component>
</template>

<script>
import { get } from 'lodash-es';
import { mapGetters, mapState } from 'vuex';

import analyticsMixin from '~coreModules/analytics/js/analytics';
import homepageMetaMixin from '~coreRoutes/homepage/js/homepage-meta';

import { HOMEPAGE_MODULE_NAME } from '~coreRoutes/homepage/js/homepage-store';

export default {
    name: 'BaseHomepageContainer',
    mixins: [
        analyticsMixin,
        homepageMetaMixin,
    ],
    props: {
        layout: {
            type: [Function, Object],
            required: true,
        },
        anonymousHomepage: {
            type: [Function, Object],
            required: true,
        },
        contentfulContentBlock: {
            type: [Function, Object],
            required: true,
        },
    },
    computed: {
        ...mapGetters([
            'loggedIn',
        ]),
        ...mapState(HOMEPAGE_MODULE_NAME, {
            homepageContent: state => state.contentPage,
        }),
        authenticatedHomepageContent() {
            return get(this.homepageContent, 'rows');
        },
        anonymousHomepageContent() {
            return get(this.homepageContent, 'content');
        },
    },
};

</script>
