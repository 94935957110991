import jsonLDMixin from '~coreModules/core/js/seo/json-ld';

export default {
    mixins: [jsonLDMixin],
    getJsonLD() {
        const userState = this.$userState;

        return {
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            url: `${userState.baseUrl}`,
        };
    },
};
