export default {
    getPageTitle() {
        return this.$t(this.SITE_META.homepageTitle);
    },
    getMetaTags() {
        return [{
            name: 'description',
            content: this.$t(this.SITE_META.homepageDescription),
        }, {
            property: 'og:title',
            content: this.$t(this.SITE_META.homepageOGTitle),
        }, {
            property: 'og:description',
            content: this.$t(this.SITE_META.homepageOGDescription),
        }];
    },
};
