import { getContentfulPageContentForSlug } from '~coreModules/contentful/js/contentful-client';
import { shapeContentfulContentPageResponse } from '~coreModules/contentful/js/contentful-utils';
import { CONTENT_TYPES } from '~coreModules/contentful/js/contentful-constants';
import { AB_TEST_AUTH_HOME_PAGE } from '~coreModules/ab-testing/js/ab-testing-constants';
import { AB_TESTING_MODULE_NAME } from '~coreModules/ab-testing/js/ab-testing-store';

export const HOMEPAGE_MODULE_NAME = 'homepage';

export const FETCH_HOMEPAGE_CONTENT = 'FETCH_HOMEPAGE_CONTENT';
export const SET_HOMEPAGE_CONTENT = 'SET_HOMEPAGE_CONTENT';

export default {
    namespaced: true,

    state: () => ({
        contentPage: null,
    }),

    /* eslint-disable no-param-reassign */
    mutations: {
        [SET_HOMEPAGE_CONTENT](state, shapedContent) {
            state.contentPage = shapedContent;
        },
    },

    /* eslint-enable no-param-reassign */
    actions: {
        [FETCH_HOMEPAGE_CONTENT]({ commit, rootGetters }, {
            slug,
            contentType = CONTENT_TYPES.CONTENT_PAGE,
            isTimed = false,
        }) {
            const homepageSlug = rootGetters[`${AB_TESTING_MODULE_NAME}/getSlug`](slug, AB_TEST_AUTH_HOME_PAGE);

            return getContentfulPageContentForSlug({
                contentfulSvc: this.$contentfulSvc,
                contentType,
                slug: homepageSlug,
                isTimed,
            })
                .then((res) => {
                    const content = contentType === CONTENT_TYPES.CUSTOM_CONTENT_PAGE ?
                        res :
                        shapeContentfulContentPageResponse(res);

                    commit(SET_HOMEPAGE_CONTENT, content);
                })
                .catch((error) => {
                    this.$logger.debugError(`Failed to fetch homepage content for ${slug}: `, error);
                });
        },
    },

    getters: {},
};
