<template>
    <BaseHomepageContainer
        :layout="Layout"
        :contentfulContentBlock="ContentfulContentBlock"
        :anonymousHomepage="commonHomepageV2 ? AnonymousHomepageV2 : AnonymousHomepage"
    />
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { mapGetters } from 'vuex';

import { UNIVERSAL_REDIRECT } from '~coreModules/core/js/store';
import homepageModule, { HOMEPAGE_MODULE_NAME, FETCH_HOMEPAGE_CONTENT } from '~coreRoutes/homepage/js/homepage-store';
import { SITE_SETTINGS_MODULE_NAME } from '~modules/site-settings/js/site-settings-store';
import { CONTENT_SLUGS, CONTENT_TYPES } from '~modules/contentful/js/contentful-constants';

import homepageJsonLDMixin from '~routes/homepage/js/homepage-json-ld';
import metaTagsMixin from '~modules/core/js/seo/meta-tags';

import BaseHomepageContainer from '~coreRoutes/homepage/components/BaseHomepageContainer.vue';
import Layout from '~modules/core/components/layouts/Layout.vue';

export default {
    name: 'CommonHomepageContainer',
    components: {
        BaseHomepageContainer,
    },
    mixins: [
        homepageJsonLDMixin,
        metaTagsMixin,
    ],
    vuex: {
        moduleName: HOMEPAGE_MODULE_NAME,
        module: homepageModule,
    },
    data: () => ({
        Layout: markRaw(Layout),
        AnonymousHomepage: markRaw(
            defineAsyncComponent(
                () => import(
                    /* webpackChunkName: "commonAnonymousHomepage" */
                    '~routes/homepage/components/CommonAnonymousHomepage.vue'),
            )),
        // NU_FEATURE: COMMON_HOMEPAGE_V2
        // https://urbnit.atlassian.net/browse/TYP-26324
        // remove component
        AnonymousHomepageV2: markRaw(
            defineAsyncComponent(
                () => import(
                /* webpackChunkName: "commonAnonymousHomepageV2" */
                    '~routes/homepage/components/CommonAnonymousHomepageV2.vue'),
            )),
        ContentfulContentBlock: markRaw(
            defineAsyncComponent(
                () => import(
                    /* webpackChunkName: "contentful-content-block" */
                    '~modules/contentful/components/ContentfulContentBlock.vue'),
            ),
        ),
    }),
    // NU_FEATURE: COMMON_HOMEPAGE_V2
    // https://urbnit.atlassian.net/browse/TYP-26324
    // remove computed block
    computed: {
        ...mapGetters(SITE_SETTINGS_MODULE_NAME, [
            'commonHomepageV2',
        ]),
    },
    fetchData({ router, store }) {
        const { getters } = store;
        const { loggedIn } = getters;

        if (loggedIn) {
            return store.dispatch(UNIVERSAL_REDIRECT, {
                router,
                route: '/rent',
            });
        }
        // NU_FEATURE: COMMON_HOMEPAGE_V2
        // https://urbnit.atlassian.net/browse/TYP-26324
        const slug = store.getters[`${SITE_SETTINGS_MODULE_NAME}/commonHomepageV2`] ?
            CONTENT_SLUGS.HOMEPAGE_SLUG_V2 : CONTENT_SLUGS.HOMEPAGE_SLUG;

        return store.dispatch(`${HOMEPAGE_MODULE_NAME}/${FETCH_HOMEPAGE_CONTENT}`, {
            slug,
            contentType: CONTENT_TYPES.CUSTOM_CONTENT_PAGE,
        });
    },
};
</script>

<style lang="scss">
</style>
